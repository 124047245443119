import React, {useEffect, useState} from 'react';
import iconTelegram from "../../img/icon-telegram.png";
import iconBack from "../../img/icon-back.svg";

const Calendar = (props) => {
    const [calendarDisplay, setCalendarDisplay] = useState('none');
    const [calendarLink, setCalendarLink] = useState(props.link);
    const [headerText, setHeaderText] = useState(props.text);
    const [btnText, setBtnText] = useState(props.btnText);
    const [cs, setCs] = useState(0);
    const handleBtnCalendar = (event) => {
        event.stopPropagation();
        // Зміна стану для відображення/сховування модального вікна
        setCalendarDisplay(calendarDisplay === 'none' ? 'block' : 'none');
    };
    useEffect(() => {
        if(cs !==0){
            setCalendarDisplay(calendarDisplay === 'none' ? 'block' : 'none');
        }else {
            setCs(1);
        }

    },[props.display]);
    useEffect(() => {
        setCalendarLink(props.link);
        setHeaderText(props.text);
        setBtnText(props.btnText);
    },[props.link]);
    const handlerBtnTg = (event) => {
        event.stopPropagation();
        window.location = "https://t.me/mariiatulhuk"
    };
    return (
        <div className='calendar-container' onClick={handleBtnCalendar} style={{
            display: calendarDisplay,
            background: `rgba(241, 241, 241, 0.91)`,
            position: 'fixed',
            top: '0vh',
            bottom: 0,
            right: 0,
            left: 0
        }}>
            <div className='info-module'
                 style={{marginInline: 'auto', marginTop: '10vh', width: 'fit-content', height: 'fit-content'}}>
                {headerText}
            </div>
            <div className='under-info-module'
                 style={{marginInline: 'auto', marginTop: '0vh', width: 'fit-content', height: 'fit-content'}}>
                Часовий пояс за Грінвічем +00:00
            </div>
            <iframe
                src={calendarLink}
                style={{border: 'solid 0px #777', borderRadius: 10, marginInline: 'auto', marginTop: '2vh'}}
                width="100%" height="55%" frameBorder="0" scrolling="no"></iframe>
            <div style={{width:'100%',display:"flex", justifyContent:'space-between',flexDirection:"row",marginTop:"20px"}}>
                <button className="btn-order" style={{background: `#969696`, marginInline: 'auto'}}
                        onClick={handleBtnCalendar}>
                    <img className='m-b-auto' src={iconBack} alt="btn-back"/>
                    <div className='ofers-btn-txt'>Повернутися</div>
                </button>
                <button className="btn-order" style={{background: `#49b279`, marginInline: 'auto'}}
                        onClick={handlerBtnTg}>
                    <img className='m-b-auto'  src={iconTelegram} alt="btn-telegram"/>
                    <span className='ofers-btn-txt'>{btnText}</span>
                </button>
            </div>


        </div>
    );
}

export default Calendar;