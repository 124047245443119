import React from 'react'

export default function NavBar({className ,children }) {
  return (
    <nav  >
      <ul className={className}>
        {children }
      </ul>
    </nav>
  )
}
