

const language = {
    lang:'ua',
    en: {
        navigation:{
            main: "Main",
            freeLessons: "Free lessons",
            contacts: "Contacts",
            courses: "Courses",
            coworking: "Coworking",
        },

        //contact-------------------------------
        emailVerificationPromptText : 'The email is not correct format ! '  ,
        contactHeader: 'Contact Us',
        contactSecondText: 'Fill out a simple contact form or just write to us at contact@evory-development.com',
        contactButtonName:"Schedule a contact",
        polityka:"Privacy Policy",
    },
    ua:{
        navigation:{
            main: "Головна",
            freeLessons: "Безкоштовні заняття",
            contacts: "Контакти",
            courses: "Курси",
            coworking: "Співпраця",
        },
        //contact-------------------------------
        emailVerificationPromptText : 'Не правильний формат емейлу !'  ,
        contactHeader : 'Напиши до нас ',
        contactSecondText:"Заповни просту контактну форму або просто напиши до нас на contact@evory-development.com",
        contactButtonName:"Сконтакутися",
        polityka:"Політика конфіденційності",
    },

}
export default language;