import React from 'react';

function Footer(props) {
    return (
        <div className='wrapper'>
            <div style={{height: 500, width: '100%'}}></div>
            <div className='footer-container'>

                <div className='footer-block'>
                    <div className='footer-el'>
                        <label htmlFor="footer-name">назва фірми</label>
                        <p id='footer-name' className='footer-text'>{props.name}</p>
                    </div>
                    <div className='footer-el'>
                        <label htmlFor="footer-name">NIP</label>
                        <p className='footer-text'>{props.nip}</p>
                    </div>
                    <div className='footer-el'>
                        <label htmlFor="footer-name">REGON</label>
                        <p className='footer-text'>{props.regon}</p>
                    </div>

                </div>

            </div>
        </div>

    );
}

export default Footer;