import React, {useContext, useState} from 'react';
import DetailsContext from "../../providers/DetailsContext";
import Calendar from "../../views/main/Calendar";

const Offers = (props) => {
    const {y,lang} = useContext(DetailsContext);
    const [module,setModule] = useState("Типи занятть");
    const [calendarDisplay,setCalendarDisplay] = useState("none");
    const [calendarLink,setCalendarLink] = useState("c");
    const [calendarHeader,setCalendarHeader] = useState("_");
    const [btnText,setBtnText] = useState("_");
    const handlerBtnCalendar = (event) => {
        event.stopPropagation();
        setBtnText("Пробне заняття");
        setCalendarLink("https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=Europe%2FLondon&bgcolor=%237986CB&showTitle=0&showNav=0&showDate=0&showPrint=0&showTabs=0&showCalendars=0&hl=uk&mode=WEEK&src=MDNiNGFiOGFlYzc2MDI4ZGVmOTk2MDU3OWRiZTQ0Zjg3OWEzMWQ5ZjIwNzNmZWZiOWFkN2M1N2E2ZjEyYTExOUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%230B8043");
        setCalendarHeader("Тут можна подивитися aктуальні вільні години які можуть бути Вам запропоновані");
        setCalendarDisplay(calendarDisplay === 'none' ? 'block' : 'none');

    };
    const handlerBtnCalendarSpeaking = (event) => {
        event.stopPropagation();
        setBtnText("Приєднатися");
        setCalendarLink("https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=Europe%2FLondon&bgcolor=%237986CB&showTitle=0&showNav=0&showDate=0&showPrint=0&showTabs=0&showCalendars=0&mode=WEEK&hl=uk&src=ODYyZDUxMzZhNTViYWY3MGI2NzNlOWEwMzVjNzZiZDFlY2FhYzVlZDEwZDhmZGY1MzU2NDdlYWEwYWM3NjY0MUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23F09300");
        setCalendarHeader("Тут можна подивитися aктуальні години проведення Speaking club")
        setCalendarDisplay(calendarDisplay === 'none' ? 'block' : 'none');
    };
    return (
        <div className="wrap-content">
            <div className='unwrap-block'>
                <div className="offers-container">
                    <h2 className='offers-module-name'>{module}</h2>
                    <div className="offers-wraper">
                        <div className="offers-block">
                            <div className="offers-img"></div>
                            <h3 className="offers-title-text">Індивідуальні
                                <b className='price' style={{position:'absolute',top:0,bottom:0, right:0, }} >8 £</b>
                            </h3>
                            <p className="offers-text">Особистий кейс</p>
                            <p className="offers-text">Індивідуальні завдання</p>
                            <p className="offers-text">Квізи по матеріалу з кейсу</p>
                            <p className="offers-text">Підлаштування під тип сприйняття</p>
                            <p className="offers-text">Лексика з Вашого середовиша</p>
                            <div className="ofers-btn" onClick={handlerBtnCalendar}>Вільні години</div>
                        </div>
                        <div className="offers-block">
                            <div className="offers-img"></div>
                            <h3 className="offers-title-text">Групові<b className='price' style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                            }}>3 - 5 £</b></h3>
                            <p className="offers-text">Груповий кейс</p>
                            <p className="offers-text">Групові завдання</p>
                            <p className="offers-text">Квізи по матеріалу з кейсу</p>
                            <p className="offers-text">Загальний курс за рівнем групи</p>
                            <div className="ofers-btn" onClick={handlerBtnCalendar}>Вільні години</div>
                        </div>
                        <div className="offers-block">
                            <div className="offers-img"></div>
                            <h3 className="offers-title-text">Speaking club<b className='price' style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                            }}>Free</b></h3>
                            <p className="offers-text">Тематичні розмови</p>
                            <div className="ofers-btn" onClick={handlerBtnCalendarSpeaking}>Години проведення</div>
                        </div>
                    </div>

                </div>
            </div>
            <Calendar display={calendarDisplay} link={calendarLink} text={calendarHeader} btnText={btnText} />

        </div>

    );
}


export default Offers;