import React, {useContext, useState} from 'react';
import Layout from "../shared/Layout";
import DetailsContext from "../../providers/DetailsContext";

const FreeLessons = () => {
    const {y,lang} = useContext(DetailsContext);
    const [module,setModule] = useState('freeLessons');
    return (
        <Layout>
            <main className='main-container'>
                <h1 className='info-not-acces'>Контент у розробці</h1>
            </main>
        </Layout>
    );
}

export default FreeLessons;