import React, {useContext, useEffect, useState} from 'react';
import DetailsContext from "../../providers/DetailsContext";
import mariaPhoto from  "../../img/present-photo.webp";
import iconTelegram from "../../img/icon-telegram.png"
import imgMetodologi from "../../img/img-metodo.png"
import imgInteractive from "../../img/interactive.webp"
import imgLive from "../../img/live-lang.webp"
import imgIndividual from "../../img/individual.webp"

const Presentation = (props) => {
    const {y,lang} = useContext(DetailsContext);
    const [module,setModule] = useState("MobileView");
    let {x} = y;
    const [display,setDisplay] = useState('apears');
    const [height , setHeight] = useState((window.innerHeight - 641)/2);
    useEffect(()=>{

    },[DetailsContext]);
    useEffect(() => {
        if(y>4200+height){
        }
    },[y]);
    const handlerBtnTg = (event) => {
        event.stopPropagation();
        alert("Контент в розробці =)");
    };
    return (
        <div className="wrap-content">
            <div className="main-img-container">
                <img src={mariaPhoto} className="main-img-presetnt-photo" alt=""/>
                <div className="main-img-text-container">
                    <h1 className="main-img-text-h1">
                        <strong>Англійська з нуля</strong> </h1>
                    <h1 className="main-img-text-h2" style={{marginTop:10,}}> заняття та
                        інтерактивний контент  для кожного </h1>

                </div>
                <div className="main-img-btn"  >


                </div>
                <button className="btn-ma" onClick={handlerBtnTg}>
                    <img src={iconTelegram} alt="btn-telegram"/>
                    <span>Пройти Тест </span>
                </button>

            </div>
            <div className="wrap-block" >
                <div className="metodologi-container" >
                    <div className="metodologi-header" >

                        <h2 className="text-h2 line-under"  >Методикa викладання</h2>
                    </div>
                    <div className="metodologi-body">
                        <div className="metodologi-body-elem el1">
                            <img src={imgInteractive} className="metodologi-body-img1" alt="imgInteractive"/>
                            <div className="metodologi-body-part">
                                <h3 className="text-h3" >Інтерактивність</h3>
                                <p className="text-p" > Окрім онлайн зустрічей з викладачем ви отримуєте у приватному чаті телеграму різного роду додатковий інтерактивний персоналізований контент з актуальних лекцій,
                                    де будуть аудіо, відео, цікаві квізи, і тести на закріплення знань.
                                </p>
                            </div>

                        </div>
                        <div className="metodologi-body-elem el2">
                            <img src={imgLive} className="metodologi-body-img2" alt="imgLive"/>
                            <div className="metodologi-body-part">
                                <h3 className="text-h3" >Жива мова</h3>
                                <p className="text-p" > Залучення актуальних інтернет ресурсів та вашого середовища з використанням лексики відповідно до ваших потреб ( тік ток , ютюб , інстаграм тощо)</p>

                            </div>
                        </div>
                        <div className="metodologi-body-elem el3">
                            <img src={imgIndividual} className="metodologi-body-img3" alt="imgIndividual"/>
                            <div className="metodologi-body-part">
                                <h3 className="text-h3" >Цілковита індивідуальність</h3>
                                <p className="text-p" > Усім відомо, що нова інформація засвоюється у кожної людини по-різному: хтось сприймає все на слух, хтось письмово, деякі тільки візуально і т.д. Ось, наприклад, я сприймаю інформацію письмово: на даний момент я вивчаю три мови, тож аби запам’ятати нові слова, мені треба їх записати і добре завчити:)Тож беручи під увагу схильність студента до сприйняття інформації підлаштовується ваше навчання </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="main-desc-container" style={{width: 'fit-content',display:'none'}} >

                <h1 className=" text-h1"
                    style={{fontSize: 24,marginBottom: 20, width: 'fit-content', marginRight: 0, textAlign: 'left',}} >Рада Вас вітати на цій сторінці </h1>
                <p className="text-p" >
                    Мене звати Марія і я студентка 3 курсу філологічного факультету. Я майбутній філолог і перекладач (англійська, німецька мови). Маю впевнений рівень В2 з англійської і планую складати CAE (на рівень С1). В грудні 2022 року самостійно приїхала до Великобританії за спонсорською програмою “Homes for Ukraine” і прожила 9 місяців у Лондоні. Після цього я переїхала у Варшаву, де зараз і знаходжусь. На даний момент, моя основна діяльність - це викладання.
                </p>
            </div>
        </div>
    );
}

export default Presentation;