import logo from './logo.svg';
import './App.css';
import DetailsContext from "./providers/DetailsContext";
import Header from "./views/main/Header";
import {useEffect, useRef, useState} from "react";
import language from "./variables/language";
import { useScroll } from "@reactuses/core";
import { BrowserRouter as Router, Routes, Route , useLocation, useNavigate } from 'react-router-dom';
import Main from "./pages/main";
import FreeLessons from "./pages/free-lessons";
import Courses from "./pages/courses";
import Contacts from "./pages/contacts";
import Coworking from "./pages/coworking";
import { Helmet } from 'react-helmet';

function App() {
    let [lang,setLang] = useState( "ua");
    let [title,setTitle] = useState( "Репетитор англійської | Англійська з нуля");
    let [description,setDescription] = useState( "Репетитор англійської, з нуля та для середнього рівня, інтерактивний контент для школярів, підлітків та дорослих. Комплексний індивідуальний підхід");
    language.lang = lang;

    const elementRef = useRef<HTMLDivElement>(null);
    const [x, y, isScrolling, arrivedState, directions] = useScroll(elementRef);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        navigate(location.pathname.indexOf('login') === -1 ? location.pathname : '/');
        if(location.pathname.includes('main')){
            setTitle('Репетитор англійської | Англійська з нуля');
            setDescription('Репетитор англійської, з нуля та для середнього рівня, інтерактивний контент для школярів, підлітків та дорослих. Комплексний індивідуальний підхід');
        }
        if(location.pathname.includes('free-lessons')){
            setTitle('Репетитор англійської | Безкоштовні заняття');
            setDescription('Опис категорії безкоштовні заняття принцип праці');
        }
        if(location.pathname.includes('courses')){
            setTitle('Репетитор англійської | Комплексні курси');
            setDescription('Комплексні курси Англійської з елементами інтерактивності на досягнення результату у вивченні мови, з актуальною живою мовою');
        }
        if(location.pathname.includes('coworking')){
            setTitle('Репетитор англійської | Співпраця');
            setDescription('Актуальні пропозиції та невеличкий опис');
        }
        if(location.pathname.includes('contacts')){
            setTitle('Репетитор англійської | Контакт');
            setDescription('Усі форми контакту для запису на занняття чи отримання додаткової інформації');
        }
    }, [navigate, location.pathname]);

  return (
    <div className="App">
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
        </Helmet>
      <div className='wrapper' >
        <div className='container-flexible'>
          <DetailsContext.Provider value={{y,lang,setLang}} >
              <Routes >
                  <Route index element={<Main />}/>
                  <Route path='/main' element={<Main/>}/>
                  <Route path='/free-lessons' element={<FreeLessons />}/>
                  <Route path='/courses' element={<Courses />}/>
                  <Route path='/coworking' element={<Coworking />}/>
                  <Route path='/contacts' element={<Contacts />}/>
              </Routes>
          </DetailsContext.Provider>
        </div>
      </div>
    </div>
  );
}

export default App;
