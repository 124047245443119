import { useLocation, Link  } from 'react-router-dom';
import React, { useContext,useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import NavBar from '../../components/layout/NavBar';
import NavBtn from '../../components/layout/NavBtn';
import language from '../../variables/language';

import NavOpenBtn from '../../components/layout/NavOpenBtn';

//images 
import iconUsers from '../../img/icon-users.svg';
import iconMenuOpen from '../../img/icon-menu-open.svg';
import Header from "../../views/main/Header";
import Footer from "../../views/main/Footer";


export default function Layout({children}) {
   const {y,lang} = useContext(DetailsContext);
   const [iconTheme , setIconTheme] = useState();

   const [navBar , setNavBar ] = useState(localStorage.getItem("nav-bar-open"));
   const [widthNav, setWidthNav] = useState(localStorage.getItem("nav-bar-open")?'300px':'0px');
   const [widthText, setWidthText] = useState('fit-content');

   const location = useLocation();

   const [module,setModule] = useState('navigation');


   
    useEffect(() => {
      init();
    }, []);

   const init = () => {
      // console.log("width:"+ widthNav+", navBar:"+navBar +", localS:"+localStorage.getItem("nav-bar-open"));
      if(navBar==='true'){
         setNavBar('false');
         setWidthNav("0px");
         setWidthText("0px");
      }else{
         setNavBar('false');
         setWidthNav("0px");
         setWidthText("0px");
      }

   }


   const handlerNavBar = () => {
      if(navBar==='true'){
         setNavBar('false');
         setWidthNav("0px");
         setWidthText("0px");
         localStorage.setItem('nav-bar-open', false);
      }else{
         setNavBar('true');
         setWidthNav("250px");
         setWidthText("fit-content");
         localStorage.setItem('nav-bar-open', true);
      }
   };
   

 
   return (
      <div className='wrapper' style={
         {

            '--width-nav': widthNav ,
            '--nav-text-width': widthText ,
         }
         }>
         

         <div className='wrap-container' style={{minWidth:'available',width:"available",}}>
            <Header/>
            <NavOpenBtn path={iconMenuOpen} onClick={handlerNavBar}   name="menu"/>
            <main className='main-block' onClick={handlerNavBar}>
               {children}
            </main>
            <Footer name={'Mariia Tulhuk lekcje angielskiego'} nip={'9512588337'} regon={'527617356'} />
         </div>

      </div>
   );
 }
