import React, {useContext, useState} from 'react';
import Layout from "../shared/Layout";
import DetailsContext from "../../providers/DetailsContext";
import Presentation from "./Presentation";
import Calendar from "../../views/main/Calendar";
import Offers from "./Offers";

const Main = () => {
    const {y,lang} = useContext(DetailsContext);
    const [module,setModule] = useState('main');
    return (
        <Layout>
            <main className='main-container' >
                <Presentation/>
                <Offers/>

            </main>
        </Layout>
    );
}

export default Main;