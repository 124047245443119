import React, {useContext, useEffect, useState} from 'react';
import DetailsContext from '../../providers/DetailsContext';
import { useLocation, Link  } from 'react-router-dom';
import language from "../../variables/language";
import NavOpenBtn from "../../components/layout/NavOpenBtn";
import iconMenuOpen from "../../img/icon-menu-open.svg";
import NavBtn from "../../components/layout/NavBtn";
import iconUsers from "../../img/icon-users.svg";
import NavBar from "../../components/layout/NavBar";

function Header(props) {
    const location = useLocation();
    const {y,lang} = useContext(DetailsContext);
    const [module,setModule] = useState('navigation');
    const [main, setMain] = useState(language[lang][module].main);
    const [freeLessons, setFreeLessons] = useState(language[lang][module].freeLessons);
    const [contacts, setContacts] = useState(language[lang][module].contacts);
    const [courses, setCourses] = useState(language[lang][module].courses);
    const [coworking, setCoworking] = useState(language[lang][module].coworking);
    useEffect(()=>{
        // set new lang text here
        setMain(language[lang][module].main);
        setFreeLessons(language[lang][module].freeLessons);
        setContacts(language[lang][module].contacts);
        setCourses(language[lang][module].courses);
        setCoworking(language[lang][module].coworking);
    },[lang]);
    return (
        <header>
            <div className='header-main'>
                <div className='h-logo' >
                    <h1>English from zero</h1>
                    <p>Англійська з нуля</p>
                </div>
                <NavBar className="h-navbar no-select ">


                    <Link  to='/main'>
                        <NavBtn name={language[lang][module].main} path={iconUsers} location={location.pathname.indexOf('main') === -1 ? '' : 'focused'}>
                        </NavBtn>
                    </Link>
                    <Link  to='/free-lessons'>
                        <NavBtn name={language[lang][module].freeLessons} path={iconUsers} location={location.pathname.indexOf('free-lessons') === -1 ? '' : 'focused'}>

                        </NavBtn>
                    </Link>
                    <Link  to='/courses'>
                        <NavBtn name={language[lang][module].courses} path={iconUsers} location={location.pathname.indexOf('courses') === -1 ? '' : 'focused'}>

                        </NavBtn>
                    </Link>
                    <Link  to='/coworking'>
                        <NavBtn name={language[lang][module].coworking} path={iconUsers} location={location.pathname.indexOf('coworking') === -1 ? '' : 'focused'}>

                        </NavBtn>
                    </Link>
                    <Link  to='/contacts'>
                        <NavBtn name={language[lang][module].contacts} path={iconUsers} location={location.pathname.indexOf('contacts') === -1 ? '' : 'focused'}>

                        </NavBtn>
                    </Link>

                </NavBar>
            </div>
        </header>
    );
}

export default Header;